<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab>
          <template #title>
            <feather-icon icon="TagIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{  $t('Routes.editTypeEquipment')  }}</span>
          </template>
          <type-equipment-edit />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="HardDriveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{  $t('AppTI.equipment.models')  }}</span>
          </template>
          <equipment-list/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import tiService from '@/services/informationTecnology.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import TypeEquipmentEdit from './TypeEquipmentEdit.vue'
import EquipmentList from './EquipmentList.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    TypeEquipmentEdit,
    EquipmentList,
  },
  setup() {
    const typeEquipmentData = ref(null)
    const { fetchTypeEquipment } = tiService()
    fetchTypeEquipment(router.currentRoute.params.tipoEquipoId, data => {
      typeEquipmentData.value = data
    })

    return {
      typeEquipmentData,
    }
  },
}
</script>

<template>
  <div>
    <list
      ref="refTypeModelsList"
      sort-by="tipoEquipoMarcaModeloId"
      refetch-records-name="modelos"
      key-field="TipoEquipoMarcaModeloId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchModels"
      :sort-dir-desc="false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import tiService from '@/services/informationTecnology.service'
import List from '@/components/List.vue'
import router from '../../../../router'

export default {
  components: {
    List,
  },
  setup() {
    const refTypeModelsList = ref(null)
    const { fetchModels } = tiService()
    const filters = ref([
      {
        type: 'select',
        name: 'tipoEquipoId',
        label: i18n.t('AppTI.filters.type'),
        value: router.currentRoute.params.tipoEquipoId,
        options: [],
        domainName: 'tipoEquipo',
      },
    ])
    const tableColumns = [

      {
        key: 'tipoEquipoMarcaModeloId',
        label: i18n.t('AppTI.columns.id'),
        sortable: true,
        sortKey: 'TipoEquipoMarcaModeloId',
      },
      {
        key: 'nombre',
        label: i18n.t('AppTI.equipment.model'),
        sortable: false,
      },
      {
        key: 'tipoMarca.nombre',
        label: i18n.t('AppTI.equipmentInfo.brand'),
        sortable: false,
      },
      {
        key: 'tipoEquipo.nombre',
        label: i18n.t('AppTI.equipment.type'),
        sortable: false,
      },
    ]
    const tableActions = ref([])
    const actions = ref([])
    return {
      refTypeModelsList,
      filters,
      tableColumns,
      tableActions,
      actions,
      fetchModels,
    }
  },
}
</script>
